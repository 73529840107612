import React from "react"
import "./index.scss"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import SEO from "../../components/seo"

import MainFrameCover from "../../components/MainFrameCover"
import ACFWYSIWYG from "../../components/Content/ACF/WYSIWYG"

import Footer from "../../components/Footer"


function Page(props) {
  const { data, pageContext } = props

  const article = data?.directus?.articles[0]

  const prettyDate = new Date(article?.publish_date).toLocaleDateString('pl-PL',{ year: 'numeric', month: 'long', day: 'numeric' })

  return (
    <>
      <MainFrameCover />

      <main id="mainframe" className={`active main-article`}>
        <SEO
          title={article?.translations[0]?.title}
          siteTitle={"IWM"}
          description={article?.translations[0]?.summary} 
        />
        <section className="article-hero c0 padd-top-2">
          <div className="hero-wrap">
            <div className='aspect-ratio'>
              <GatsbyImage
                  image={article?.image?.imageFile?.childImageSharp?.gatsbyImageData}
                  alt=""
                  layout="fixed"
                  objectFit="cover"
                  objectPosition={"50% 50%"}
                  className="bg"
                />
            </div>
          </div>
        </section>
        <section className="article-content c0 padd">
          <div className="content-wrap">
            <div className='content'>
              <div className='heading'>
                <h2>{article?.translations[0]?.title}</h2> 
              </div>
              <div className='date'>
                <p>Opublikowano: {prettyDate} </p>
              </div>
              <div className='copy padd-top'>
                <ACFWYSIWYG wysiwyg={article?.translations[0]?.body} />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  )
}

export default Page

export const query = graphql`
  query($slug: String!) {
    directus {
      articles(filter: {slug: {_eq: $slug}, status: {_eq: "published"}, translations: {languages_id: {name: {_eq: "Polish"}}}}, limit: 1 ) {
        id
        slug
        status
        publish_date
        publish_date_func {
          day
          month
          year
        }
        image {
          ...imgFull
        }
        translations(filter: {languages_id: {name: {_eq: "Polish"}}}) {
          id
          title
          summary
          body
        }
      }
    }
  }
`